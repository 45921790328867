@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #5b5fd9 !important;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #ffffff !important;
  font-family: 'Rubik', sans-serif !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .loading-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: 25%;
    animation: loading-page-effect infinite 2s;
  }
  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .loading-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: 25%;
    animation: loading-page-effect infinite 2s;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .loading-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: 25%;
    animation: loading-page-effect infinite 2s;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .loading-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: 10%;
    animation: loading-page-effect infinite 2s;
  }

  .banner-illustration {
    background-image: url(/static/media/logo.7c2e13a1.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 90% !important;
    background-size: contain !important;
  }
  .bl-primary {
    border-left: 2px solid #5b5fd9;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .loading-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: 10%;
    animation: loading-page-effect infinite 2s;
  }

  .banner-illustration {
    background-image: url(/static/media/logo.7c2e13a1.svg) !important;
    background-repeat: no-repeat !important;
    background-position: 90% !important;
    background-size: contain !important;
  }
  .br-primary {
    border-right: 2px solid #5b5fd9;
  }
}

.border-top-custom {
  border-top: 4px dotted #5b5fd985;
}

/* Button */
.btn-primary {
  background: #5b5fd9 !important;
  border-color: #5b5fd9 !important;
}

.btn-primary:hover {
  background: #484cb5 !important;
  border-color: #484cb5 !important;
}

.btn-primary:focus {
  box-shadow: 0px 0px 0px 2px #5b5fd996 !important;
}

.btn-outline-primary {
  background: transparent !important;
  border-color: #5b5fd9 !important;
  color: #5b5fd9 !important;
}

.btn-outline-primary:hover {
  background: #5b5fd9 !important;
  border-color: #5b5fd9 !important;
  color: #ffffff !important;
}

.btn-outline-primary:focus {
  box-shadow: 0px 0px 0px 2px #5b5fd996 !important;
}

.primary-white {
  color: #212121 !important;
  background: #ffffff !important;
  border-color: #c8c8c8 !important;
}

.primary-white:hover {
  background: #5b5fd95b !important;
  border-color: #5b5fd9 !important;
  color: #ffffff !;
}

.primary-white:focus {
  box-shadow: 0px 0px 0px 2px #5b5fd996 !important;
}

/* .btn-primary-3d {
  background: #5b5fd9 !important;
  border-color: #5b5fd9 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 0px 1px  #149966 !important;
} */

/* Card */
.card-effect {
  bottom: 0px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: bottom 0.3s, box-shadow 0.5s;
}
.card-effect:hover {
  animation: card-effect normal 0.5s;
  background: #9ab3f5;
  color: #ffffff;
  bottom: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.111);
  transition: bottom 0.3s;
}

.card-effect img:hover {
  animation: card-effect-img 1.2s;
}

@keyframes card-effect-img {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(20deg);
  }
  60% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes card-effect {
  from {
    background: #ffffff;
  }
  to {
    background: #9ab3f5;
  }
}

@keyframes push-effect {
  from { 
    box-shadow: 0 2px 0px 1px  #149966;
  }
  to {
    box-shadow: none;
  }
}

@keyframes loading-page-effect {
  /* 0% {
    opacity: 0%;
  }
  25% {
    opacity: 20%;
  }
  50% {
    opacity: 50%;
  }
  70% {
    opacity: 70%;
  }
  100% {
    opacity: 100%;
  } */
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


